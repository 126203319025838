﻿.l-mobile-nav {
	@include box-sizing();
	background: $colorPurplePale;
	bottom: 0;
	color: $colorWhite;
	display: flex;
	flex-direction: column;
	left: -400px; // $navMobileWidth + 25
	max-width: $navMobileWidth;
	position: fixed;
	text-align: left;
	top: 0;
	transition: left .4s ease-in-out;
	width: 100vw;
	z-index: 2000;

	&.active,
	.active {
		// here as a general class is __sub and __group also use it 
		left: 0;
	}

	&.visible,
	.visible {
		display: block;
	}

	&__header {
		flex-grow: 0;
		background: $colorPurplePale;

		&__container {
			display: flex;
			height: 100%;
		}

		&__button {
			width: 25%;
			position: relative;

			&__link {
				color: $colorBrightGrey;
				display: flex;
				font-size: $fontSize;
				line-height: $fontHeight;
				text-decoration: none;
				text-transform: uppercase;
				box-sizing: border-box;

				&:hover {
					color: $colorBrightGrey;
					text-decoration: none;
				}

				&--close,
				&--back {
					&:before {
						position: absolute;
						top: 50%;
						left: 50%;
					}
				}

				&--close {
					&:before {
						font-family: $fontAwesome;
						content: "\f00d";
						transform: translate(0%, -50%);
					}
				}

				&--back {
					display: none;

					&:before {
						font-family: $fontAwesome;
						content: "\f060";
						transform: translate(-100%, -50%);
					}
				}
			}
		}

		&__logo {
			width: 50%;

			a {
				width: 100%;
				height: 100%;
				padding: 10px 0;
				box-sizing: border-box;

				img {
					margin-top: 5px;
					height: 75%;

					@media only screen and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
						height: auto;
					}
				}
			}
		}
	}

	&__footer {
		background: $colorPurplePale;
		display: flex;
		flex-grow: 0;
		justify-content: center;
		gap: 15px;
		padding: 10px 0;
		margin-bottom: 90px; // TO accomodate the 'We'll call you boxes'

		&__button {
			width: 40%;

			&__link {
				width: 100%;
				color: $colorWhite;
				background: $colorAction;
				border: 1px solid $colorAction;
				padding: 7px 20px;
				box-sizing: border-box;
				text-align: center;
				font-family: $fontBaseMedium;
				font-size: 15px;
				height: 41px;
				white-space: nowrap;
				overflow: hidden;

				&:hover {
					text-decoration: none;
					color: $colorAction;
					background-color: $colorWhite;
				}
			}
		}
	}

	&__divider {
		margin: 0 30px;
		border-bottom: 1px solid $colorBrightGrey;
	}

	&__content {
		position: relative;
		display: flex;
		flex-direction: column;
		flex: 1;

		&__menu {
			&__item {
				padding: 0;
			}

			&__link {
				@include box-sizing();
				color: $colorBrightGrey;
				display: flex;
				font-size: 15px;
				line-height: $fontHeight + 1;
				padding: 10px 30px;
				text-transform: uppercase;
				z-index: 1;

				&:hover,
				&.active {
					color: $colorWhite;
					text-decoration: none;
					background-color: $colorAction;
				}

				&.has-sub {
					padding-right: 35px;
					display: flex;
					align-items: center;

					&:after {
						font: var(--fa-font-solid);
						content: '\f054';
						margin-left: auto;
						font-size: 12px !important;
					}
				}

				&.l-mobile-nav__content__menu__link--wishlist {
					span {
						margin-left: auto;
						font-size: 19px;
						margin-top: 1px;
					}
				}

				&.l-mobile-nav__content__menu__link--login {
					i {
						margin-left: auto;
						font-size: 19px;
						margin-top: 4px;
					}
				}
			}

			&__nolink {
				color: $colorBrightGrey;
				display: flex;
				font-size: 15px;
				line-height: $fontHeight + 1;
				padding: 10px 30px;
				text-transform: uppercase;
				z-index: 1;
			}

			&__title {
				padding: 0;
				background: $colorPurplePale;

				&__link {
					@include box-sizing();
					color: $colorBrightGrey;
					font-size: 15px;
					line-height: $fontHeight + 1;
					padding: 10px;
					text-transform: uppercase;
					z-index: 1;
					text-align: center;
					width: 100%;

					&:hover,
					&.active {
						color: $colorWhite;
						text-decoration: none;
						background-color: $colorAction;
						border-left: $colorPurplePale solid 15px;
						border-right: $colorPurplePale solid 15px;
					}
				}
			}
		}
	}

	&__scroller {
		overflow-y: scroll;
		height: 100%;
	}

	&__sub,
	&__sub__group {
		position: absolute;
		top: 1px;
		bottom: 0;
		left: -($navMobileWidth);
		width: 100vw;
		max-width: $navMobileWidth;
		z-index: 1100;
		transition: left .4s ease-in-out;
		background: $colorPurplePale;
		display: none;
	}

	&__sub__group {
		width: 100%;
		z-index: 3000;
	}
}
