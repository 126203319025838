﻿$colorTransparentDark: rgba($colorAction, .9);

@mixin linkStyle {
	color: inherit;
	display: block;
	font-size: $fontSize;
	line-height: $fontHeight;
	margin: 0;
	margin: 0 15px;
	padding: 3px 0;
	text-decoration: none;

	&:hover,
	&.active {
		color: $colorBlue;
		text-decoration: none;
	}

	@include mq-min($bpDesktop) {
		margin: 0px 20px;
	}

	@include mq-min($bpLarge) {
		margin: 0px 30px;
	}
}

.l-nav-sub {
	@include box-sizing();
	display: none;
	left: 0;
	margin: 0;
	padding: 17px 0;
	position: absolute;
	text-align: left;
	top: 100%;
	width: auto;
	background: $colorTransparentDark;
	z-index: 1;

	@include mq-min($bpDesktop) {
		padding: 22px 0;
	}

	&--mega {
		right: 0;
		width: auto;
		margin: 0;

		@include mq-min($bpDesktop) {
			margin: 0px 15px;
		}

		@include mq-min($bpLarge) {
			margin: 0px 30px;
		}
	}

	&__content {

		&__title {
			margin: 0;

			&--alt {
				margin: 10px 0 15px;
			}

			&__link {
				@include linkStyle;
				text-transform: uppercase;

				&--alt {
					text-transform: uppercase;
					border-bottom: 1px solid currentColor;
				}
			}
		}

		&__col {
			@include box-sizing();
			display: inline-block;
			font-size: $fontSize;
			line-height: $fontHeight;
			margin: 0 0 -15px 0;
			padding: 0;
			vertical-align: top;
			width: 25%;
		}

		&__menu {
			padding: 0 0 15px 0 !important;
		}
	}

	&__content__menu,
	&__menu {
		margin: 0;
		padding: 0;

		&__item {
			margin: 0;
			padding: 0;
		}

		&__link {
			@include linkStyle;
		}
	}

	&__back {
		display: none;
	}

	&__search-sub {
		bottom: 0;
		left: 0;
		position: absolute;
		top: 125px;
		z-index: 13;
		background: $colorTransparentDark;

		@include mq-min($bpDesktop) {
			bottom: auto;
		}

		&__inner {
			padding: 20px 20px 0;

			@include mq-min($bpMobileLarge) {
				padding: 30px 30px 0;
			}
		}

		&__title {
			font-family: $fontSerifAlt;
			padding: 0;
			text-align: center;
			font-size: 28px;
			color: $colorWhite;

			@include mq-min($bpMobileLarge) {
				padding: 0 0 10px;
			}
		}

		&__form {
			margin: 0 auto;
			padding: 10px 0 25px 0;
			max-width: 430px;

			.form-text {
				max-width: 100%;
			}

			@include mq-min($bpMobileLarge) {
				padding: 10px 0 60px 0;
			}
		}
	}

	&__close {
		position: absolute;
		top: 0;
		right: 0;
		overflow: hidden;
		@include text-hidden;

		&:after {
			@include bg('/images/common/sprite.png', no-repeat, 0 -11px);
			content: " ";
			height: 15px;
			width: 16px;
			display: block;
			margin: -4px 16px 20px;
		}

		&:hover {
			@include opacity();
		}
	}
}
