@import "config";
@import "base/reset";

strong,
b {
	font-family: $fontBaseMedium;
	font-weight: normal;
}

// links
a {
	color: $colorLink;
	display: inline-block;
	text-decoration: none;

	&:hover {
		color: $colorLinkHover;
		text-decoration: underline;
	}

	&:focus {
		outline: 1px dotted $colorPurple;
	}
}

// headers
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $fontBase;
	font-size: $fontSize + 7;
	font-weight: 300;
	line-height: $fontHeight + 7;
	margin: 0;
	padding: 0 0 10px 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1 {
	padding-bottom: 15px;
	font-size: $fontSize + 9;
}

// content
p {
	margin: 0;
	padding: 0 0 15px 0;
}

// images
img {
	height: auto;
	width: auto;
}

// tables
table {
	tr {

		th,
		td {
			a {
				display: inline;
			}
		}
	}
}

// rules
hr {
	background-color: $colorDivider;

	&.alt {
		background-color: $colorWhite;
		margin-bottom: 20px;
	}
}

figure {
	&.tight {
		padding: 0;
	}
}
