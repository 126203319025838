.l-header {
	margin: 0 auto;
	padding: 0;
	z-index: 2000;
	position: sticky;
	width: 100%;
	top: 0;

	&__inner {
		border-bottom: 1px solid $colorDivider;
		@include box-sizing();
		margin: 0 auto;
		overflow: hidden;
		padding: 15px 0;
		max-width: $maxPageWidth;
		position: relative;

		@include mq-min($bpTabletLarge) {
			padding: 20px 0;
		}

		@include mq-min($bpDesktop) {
			padding: 25px 0;
		}

		@include mq-min($bpLarge) {
			padding: 30px 0;
			max-width: $maxPageWidthLarge;
		}

		&--no-border {
			border-bottom: 0;
		}
	}

	&__logo {
		font-size: 0;
		line-height: 0;
		margin: 0;
		padding: 0;

		&__link {
			display: inline-block;
			margin: 0 auto;
			padding: 0;
			max-width: 210px;
		}

		&__image {
			display: block;
			margin: 0;
			padding: 0;
		}
	}

	&__secure {
		background: $colorPurplePale;
		@include box-sizing();
		margin: 0 auto 15px auto;
		padding: 8px;
		text-align: center;
		max-width: $maxPageWidth;

		&__inner {
			color: $colorBlack;
			display: inline-block;
			font-family: $fontBase;
			@include min-height(16px);
			margin: 0;
			padding: 0 0 0 20px;
			position: relative;
			z-index: 1;

			&:before {
				@include bg('/images/common/sprite.png', no-repeat, -87px -1px);
				content: "";
				display: block;
				height: 14px;
				left: 0;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 2px;
				width: 11px;
				z-index: 1;
			}
		}

		@include mq-min($bpLarge) {
			max-width: $maxPageWidthLarge;
		}
	}

	&__opening-hours {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0;
		margin: 0;
		padding: 0;
		text-align: right;
		display: none;

		@include mq-min($bpDesktop) {
			display: block;
		}

		&__action {
			display: block;
			font-size: 21px;
			line-height: 21px;
			font-family: $fontBaseMedium;
			color: $colorBlue;
		}

		&__message {
			display: block;
			font-size: $fontSize;
			line-height: $fontSize;
			padding: 5px 0 0 0;
		}
	}
}
