// configs
@import "config";
@import "reset";
@import "utils";

:root {
    --maxPageWidth: #{$maxPageWidth};
    --bpMobileLarge: #{$bpMobileLarge};
    --bpTablet: #{$bpTablet};
    --bpTabletLarge: #{$bpTabletLarge};
    --bpTabletMid: #{$bpTabletMid};
    --bpDesktop:#{$bpDesktop};
    --bpLarge: #{$bpLarge};
    --bpExLarge: #{$bpExLarge};
    --scrollbarWidth: 17px;
}

// base
html {
    background: $colorPurplePale;
    color: $colorWhite;
    @include min-height(100%);
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: rgba($colorBlack, 0);
    @include text-size-adjust();
    width: 100%;
    min-width: 320px;

    @include mq-min($bpDesktop) {
        min-width: 960px;
    }

    &.bleed {
        background: $colorWhite;
        color: $colorBase;
    }

    &.lockout {
        body {
            overflow: hidden;
            padding-right: var(--scrollbarWidth);

            @include mq-max($bpDesktop - 1) {
                // Mobile devices all have 0px width scrollbars, so this is savng us a Resize event listener in JS
                padding-right: 0px;
            }
        }

        .l-utils--mobile-nav {
            display: none;
        }
    }
}

body {
    background: $colorWhite;
    color: $colorBase;
    font-family: $fontBaseLight;
    font-size: $fontSize;
    font-weight: 300;
    height: 100%;
    line-height: $fontHeight;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: center;
    min-width: 320px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    @include mq-min($bpDesktop) {
        min-width: 960px;
    }
}

.l-page {
    background: $colorWhite;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 12;
    opacity: 0;
    transition: opacity 0.15s linear;

    .css-ready & {
        opacity: 1;
    }
}

.fe-failure {
    display: none;
    // This will target the old browsers so no flex/grid magic and some duplicated t/l/b/r values needed
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    background: rgba(255, 255, 255, 0.9);
    z-index: 9999999999; // The very top!

    &.active {
        display: block;
    }

    &__msg {
        color: black;
        text-align: left;
        padding: 20px;
        background: white;
        border-radius: 13px;
        max-width: 80vw;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@import "layout/header";
@import "layout/utils";
@import "layout/mobile-nav";
@import "layout/nav";
@import "layout/nav-sub";
@import "layout/mobile-sub-nav";
@import "base/helpers";
