.js-navsearch-link {
	color: $colorBase;
}

.js-navsearch-link-menu {
	margin: 27px 0 0 10px;
}

.js-navsearch-link-menu:hover {
	color: $colorBase;
}

.l-utils {
	background: $colorPurplePale;
	color: $colorBrightGrey;
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 10px;
	box-sizing: border-box;
	font-size: 12px;

	&__phone {
		@mixin phone {
			display: flex;
			box-sizing: border-box;
			overflow: hidden;

			a {
				padding: 0 6px;
				color: inherit;
				text-decoration: none;
				max-width: 15px;
				white-space: nowrap;

				&:hover {
					text-decoration: none;
				}

				&:before {
					font-family: $fontAwesome;
					content: "\f095";
					font: var(--fa-font-solid);
					padding: 0 8px;
				}
			}

			@include mq-min($bpDesktop) {
				display: none;
			}
		}

		@include phone();

		&:before {
			border-left: 1px solid $colorBrightGrey;
			content: "";
		}

		&--booking {
			@include phone();

			@include mq-min($bpTablet) {
				&:before {
					border-left: 1px solid $colorBrightGrey;
					content: "";
				}
			}
		}
	}

	&__burger {
		display: flex;
		font-size: 18px;

		a {
			color: inherit;

			&:hover {
				color: inherit;
			}
		}

		@include mq-min($bpDesktop) {
			display: none !important;
		}
	}

	&__cta {
		display: none;
		justify-content: flex-start;

		&__action {
			padding: 0 10px;
		}

		&__message {
			padding: 0 10px;
		}

		@include mq-min($bpDesktop) {
			display: flex;
		}
	}

	&__menu {
		display: flex;
		justify-content: flex-end;
		width: 100%;
		padding: 0;

		@include mq-min($bpMobileLarge - 1) {
			li+li::before {
				border-left: 1px solid $colorBrightGrey;
				content: "";
			}
		}

		@include mq-max($bpDesktop - 1) {
			.hide-on-desktop-or-less+li::before {
				border-left: 0;
			}
		}

		&__item {
			display: flex;
			box-sizing: border-box;

			&:hover {
				margin-bottom: -10px;
				padding-bottom: 15px;
			}

			&__link {
				color: inherit;
				text-decoration: none;
				padding: 0 6px;

				&:hover {
					color: $colorBlue;
					text-decoration: none;
					padding-bottom: 10px;
					margin-bottom: -10px;
					box-sizing: border-box;
				}

				@include mq-min($bpDesktop) {
					padding: 0 25px;
				}

				@include mq-min($bpTablet) {
					padding: 0 10px;
				}

				i::before {
					padding: 0 2px;
				}
			}

			&__tooltip {
				display: none;
				position: absolute;
				top: 100%;
				min-width: 215px;
				max-width: 255px;
				background: rgba(0, 0, 0, 0.85);
				padding: 20px;
				color: $colorWhite;
				text-align: left;
				font-size: $fontSize - 1;

				&__text {
					span {
						color: $colorTeal;
					}
				}

				&__list {
					padding: 3px 0 0;

					&__item {
						&__link {
							color: $colorWhite;

							&:hover {
								color: $colorBlue;
							}

							&__text {
								overflow: hidden;
							}

							&__image {
								float: left;
								padding: 0 10px 0 0;
								max-width: 80px;
							}
						}
					}

				}
			}

			&:hover {
				.l-utils__menu__item__tooltip {
					display: block;
				}
			}
		}

		@include mq-min($bpDesktop) {
			justify-content: flex-end;
			width: auto;
		}
	}

	@include mq-min($bpTablet) {
		font-size: 13px;
	}
}

.l-utils-agents {
	background: $colorWhite;
	padding: 10px 20px;
	display: none;

	@include mq-min($bpDesktop) {
		display: flex;
	}

	&__links {
		display: none;
		font-size: $fontSize - 2;
		line-height: $fontHeight - 2;
		margin: 0;
		padding: 0;
		width: 400px;

		* {
			display: flex;
			font-size: $fontSize - 2;
			line-height: $fontHeight - 2;
			list-style: none;
			margin: 0;
			padding: 0;
			align-items: center;
		}

		a {
			color: $colorBase;
			text-decoration: none;

			&:hover,
			&:focus {
				color: $colorBase;
				text-decoration: underline;
			}
		}

		.linkdivider {
			padding: 0 5px 0 5px;
		}

		@include mq-min($bpDesktop) {
			display: flex;
		}

		@include mq-min($bpLarge) {
			width: 400px;
		}
	}

	&__info {
		display: none;
		text-align: right;
		color: $colorBase;
		font-size: $fontSize - 3;
		width: 100%;

		@include mq-min($bpDesktop) {
			display: flex;
			flex-direction: column;
		}

		&--details {
			padding: 0 0 5px 0;
		}

		&--buttonlink {
			text-decoration: none;
			width: 125px;
			margin-left: auto;
		}

		&--button {
			background-color: $colorAction;
			padding: 5px 0 5px 0;
			color: #ffffff;
			border: 1px solid $colorAction;
			box-sizing: border-box;
			font-family: $fontBaseMedium;
			text-align: center;
		}

		&--button:hover {
			background-color: #ffffff;
			color: $colorAction;
		}
	}

}
