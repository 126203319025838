.l-nav {
	background: $colorAction;
	width: 100%;
	display: flex;
	padding: 0 15px;
	font-family: $fontBase;
	font-weight: 450;
	box-sizing: border-box;
	position: relative;
	color: $colorWhite;
	height: 75px;
	z-index: 13;

	&__logo {
		min-width: 155px;
		max-width: 210px;
		display: flex;
		box-sizing: border-box;
		margin: 0 auto;

		&__link {
			display: flex;
			padding: 0 10px;
			align-items: center;
		}

		&__image {
			object-fit: contain;
		}

		&--booking {
			margin: 0 auto;
		}
	}

	&__section {
		width: 100%;
		height: 100%;
		display: none;
		padding-top: 10px;
		box-sizing: border-box;

		&--booking {
			display: none;
		}

		&__item {
			height: 100%;
			white-space: nowrap;

			&--simple {
				position: relative;
			}

			&__link {
				height: 100%;
				padding: 5px 15px 15px 10px;
				color: inherit;
				box-sizing: border-box;
				position: relative;
				text-transform: uppercase;
				display: flex;
				align-items: center;

				&__text {
					font-size: 13px;
				}

				&:hover {
					text-decoration: none;
					color: inherit;
				}
			}

			&:hover {
				border-top: 3px solid currentColor;
				margin-top: -3px;

				.l-nav__sub-nav,
				.l-header-sub-nav-arrow-up {
					display: block;
				}
			}
		}

		@include mq-min($bpDesktop) {
			display: flex;
			justify-content: flex-end;
		}


		@include mq-min($bpDesktopMid) {
			gap: 15px;
		}
	}

	&__edge {
		@mixin nav-edge {
			width: 40%;
			height: 100%;
			white-space: nowrap;
			overflow: hidden;
			align-self: center;
			justify-content: center;

			&--booking {
				display: none;
			}

			&__link {
				width: 100%;
				color: inherit;
				text-transform: uppercase;
				padding: 10px;
				display: flex;
				justify-content: flex-end;
				align-items: center;

				&:hover {
					text-decoration: none;
				}

				@include mq-min($bpDesktop) {
					width: 100%;
					padding: 20px 15px 10px 15px;
				}
			}

			@include mq-min($bpDesktop) {
				width: 10%;

				&:hover {
					border-top: 3px solid currentColor;
					margin-top: -3px;
				}
			}
		}

		@mixin nav-edge-right {
			margin-left: auto;
			justify-content: flex-end;
			text-align: right;
		}


		&__mobile {
			@mixin nav-edge-mobile {
				@include nav-edge;
				display: flex;

				@include mq-min($bpDesktop) {
					display: none;
				}
			}

			&--left {
				@include nav-edge-mobile;
			}

			&--right {
				@include nav-edge-mobile;
				@include nav-edge-right;
			}
		}
	}

	&__sub-nav {
		display: none;
		background: $colorWhite;
		position: absolute;
	}

	@include mq-min($bpDesktop) {
		height: 75px;
		margin-top: 0;
	}

}
