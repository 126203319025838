﻿.l-mobile-sub-nav {
	bottom: 0;
	display: block;
	left: -($navMobileWidth);
	top: 0;
	margin: 0;
	padding: 0;
	position: absolute;
	@include transition(all .3s);
	width: 100vw;
	max-width: $navMobileWidth;
	z-index: 1100;
	height: 100%;
	background: $colorPurplePale;

	&__content {
		background: $colorPurplePale;
		margin: 0;
		padding: 0;

		z-index: 1;

		&__col {
			margin: 0;
			padding: 0;
		}

		&__title {
			border-bottom: 1px solid $colorGrey;
			border-color: rgba($colorGrey, .2);
			margin: 0;
			padding: 0;

			&--alt {
				background: $colorWhite;
			}

			&__link {
				@include box-sizing();
				color: $colorBase;
				display: flex;
				font-family: $fontBase;
				font-size: $fontSize + 1;
				line-height: $fontHeight + 1;
				margin: 0;
				overflow: hidden;
				padding: 10px;
				position: relative;
				text-decoration: none;
				text-transform: uppercase;

				&:hover,
				&.active {
					color: $colorBase;
					text-decoration: none;
				}

				&.has-sub {
					padding-right: 15px;

					&:after {
						font: var(--fa-font-solid);
						content: '\f054';
						margin-left: auto;
					}
				}
			}
		}

		&__group {
			background: $colorOffWhite;
			bottom: 0;
			display: block;
			left: -($navMobileWidth);
			margin: 0;
			padding: 0;
			position: absolute;
			@include transition(all .3s);
			top: 0;
			width: 100%;
			z-index: 8889;

			&.visible {
				left: 0;
			}
		}

		&__menu {
			margin: 0;
			padding: 0;

			&__item {
				border-bottom: 1px solid $colorGrey;
				border-color: rgba($colorGrey, .2);
				margin: 0;
				padding: 0;
			}

			&__link {
				@include box-sizing();
				color: $colorBase;
				display: block;
				font-family: $fontBase;
				font-size: $fontSize + 1;
				line-height: $fontHeight + 1;
				margin: 0;
				overflow: hidden;
				padding: 10px;
				position: relative;
				text-decoration: none;
				text-transform: uppercase;
				z-index: 1;

				&:hover,
				&.active {
					color: $colorBase;
					text-decoration: none;
				}
			}
		}
	}

	&__menu {
		margin: 0;
		padding: 0;

		&__item {
			border-bottom: 1px solid $colorGrey;
			border-color: rgba($colorGrey, .2);
			margin: 0;
			padding: 0;
		}

		&__link {
			@include box-sizing();
			color: $colorBase;
			display: block;
			font-family: $fontBase;
			font-size: $fontSize + 1;
			line-height: $fontHeight + 1;
			margin: 0;
			overflow: hidden;
			padding: 10px;
			position: relative;
			text-decoration: none;
			text-transform: uppercase;
			z-index: 1;

			&:hover,
			&.active {
				color: $colorBase;
				text-decoration: none;
			}
		}
	}

	&.visible {
		left: 0;
	}
}
