// Effects
.rotate {
    position: relative;
    transform-origin: center;
    transform: rotate(0);
    transition-duration: 400ms;
    display: inline-flex;

    &-90 {
        transform: rotate(90deg);
    }
}


//Margins and paddings
.mb-20 {
    margin-bottom: 20px;
}

//Sizings
.w-100 {
    width: 100%;
}
