// reset: http://meyerweb.com/eric/tools/css/reset/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strong,
sub,
sup,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
	border: 0;
	font: inherit;
	font-size: 100%;
	margin: 0;
	outline: 0;
	padding: 0;
	vertical-align: baseline;
}

main,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
audio,
video,
canvas {
	display: block;
}

body {
	line-height: 1;
}

blockquote,
q {
	quotes: none;

	&:before,
	&:after {
		content: '';
		content: none;
	}
}

:focus {
	outline: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

abbr[title] {
	cursor: help;
	border: none;
	text-decoration: none;
}

fieldset {
	border: 0;
	display: block;
	margin: 0;
	padding: 0;
	position: static;
}

legend {
	left: auto;
	margin: 0;
	padding: 0;
	position: static;
	top: auto;
}

address {
	display: block;
	font-style: normal;
	margin: 0;
	padding: 0;
}

strong,
b {
	font-weight: bold;
}

em,
i {
	font-style: italic;
}

blockquote {
	margin: 0;
	padding: 0;
}

small {
	color: $colorGrey;
	font-size: 85%;
	line-height: 85%;
}

sub,
sup {
	font-size: 85%;
	line-height: 0;
	margin: 0;
	padding: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	top: -0.5em;
}

sub {
	bottom: -0.25em;
}

img {
	border: 0;
	display: block;
	margin: 0 auto;
	padding: 0;
	max-width: 100%;
}

figure {
	margin: 0;
	padding: 0 0 15px 0;
	text-align: center;

	figcaption {
		font-style: italic;
		margin: 0;
		padding: 10px 0 0 0;
	}
}

button,
html input[type="button"],
html input[type="reset"],
html input[type="submit"] {
	-webkit-appearance: button;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	margin: 0;
	padding: 0;
}

table {
	border: 0;
	border-collapse: collapse;
	margin: 0 0 15px 0;
	padding: 0;
	width: 100%;

	th,
	td {
		margin: 0;
		padding: 5px 3px;
	}
}

ul,
ol {
	list-style: none;
	margin: 0;
	padding: 0 0 10px 0;

	li {
		list-style: none;
		margin: 0;
		padding: 0 0 5px 0;
	}
}

dl {
	margin: 0;
	padding: 0 0 10px 0;

	dt {
		display: block;
		font-weight: bold;
		margin: 0;
		padding: 0 0 5px 0;
	}

	dd {
		display: block;
		margin: 0;
		padding: 0 0 5px 0;
	}
}

pre {
	background: lighten($colorRed, 50%);
	border: 1px solid $colorRed;
	color: $colorRed;
	font-family: Courier New, Courier, monospace;
	font-size: 12px;
	max-height: 300px;
	line-height: 16px;
	margin: 0 0 15px 0;
	overflow: auto;
	padding: 10px;
}

hr {
	background: $colorDivider;
	border: 0;
	height: 1px;
	margin: 5px 0 20px 0;
	padding: 0;

	@include mq-min($bpDesktop) {
		margin: 10px 0 25px 0;
	}
}
